import { PageIndexContext } from "App";
import { Section } from "components";
import { infoSubmenus } from "components/Layout/Header";
import { useContext, useEffect } from "react";
import "sass/InfoWorship.scss";

export function InfoWorshipContainer() {
  const { setCurrentPageIndex } = useContext(PageIndexContext);

  useEffect(() => {
    setCurrentPageIndex(1);
  });
  return (
    <Section
      direction="Right"
      title="소개"
      submenus={infoSubmenus}
      subtitle="예배안내"
    >
      <div className="worship-container">
        <span>주일 예배</span>
        <table className="worship-table">
          <thead>
            <tr>
              <th>예배</th>
              <th>시간</th>
              <th>장소</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1부</td>
              <td>오전 09:30</td>
              <td>3층 본당</td>
            </tr>
            <tr>
              <td>2부</td>
              <td>오전 11:00</td>
              <td>3층 본당</td>
            </tr>
          </tbody>
        </table>
        <span>주중 예배</span>
        <table className="worship-table">
          <thead>
            <tr>
              <th>예배</th>
              <th>시간</th>
              <th>장소</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>수요예배</td>
              <td>오후 07:30</td>
              <td>3층 본당</td>
            </tr>
            <tr>
              <td>금요기도회</td>
              <td>오후 08:00</td>
              <td>3층 본당</td>
            </tr>
            <tr>
              <td>새벽기도</td>
              <td>
                월 ~ 금
                <br />
                오전 05:30
              </td>
              <td>3층 본당</td>
            </tr>
          </tbody>
        </table>
        <span>부서 예배</span>
        <table className="worship-table">
          <thead>
            <tr>
              <th>예배</th>
              <th>시간</th>
              <th>장소</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>중고등부</td>
              <td>오전 09:30</td>
              <td>2층 유초등부실</td>
            </tr>
            <tr>
              <td>유초등부</td>
              <td>오전 11:00</td>
              <td>2층 유초등부실</td>
            </tr>
            <tr>
              <td>유치부</td>
              <td>오전 11:00</td>
              <td>1층 유치부실</td>
            </tr>
            <tr>
              <td>청년부</td>
              <td>오후 02:00</td>
              <td>2층 유초등부실</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Section>
  );
}
