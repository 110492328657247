import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BulletinPager, Section } from "components";
import { Bulletin, RootState } from "modules";
import "sass/Boards.scss";
import BulletinGrid from "components/bulletin/BulletinGrid";
import ModalImage from "components/common/ModalImage";
import { getBulletinsAsync } from "modules/bulletins";
import { getBulletinFileList } from "api/firebaseManager";
import MetaTag from "SEOMetaTag";
import { PageIndexContext } from "App";

export function BulletinContainer() {
  const { setCurrentPageIndex } = useContext(PageIndexContext);

  const [currentPage, setCurrentPage] = useState(1);
  const bulletinsPerPage = 10;
  // const dispatch = useDispatch();
  // const { data, loading, error } = useSelector(
  //   (state: RootState) => state.bulletins.bulletins
  // );
  // const [showModal, setShowModal] = useState(false);
  const [bulletins, setBulletins] = useState<Bulletin[]>([]);
  const [loading, setLoading] = useState(true);

  const indexOfLast = currentPage * bulletinsPerPage;
  const indexOfFirst = indexOfLast - bulletinsPerPage;

  useEffect(() => {
    setCurrentPageIndex(3);
    const response = getBulletinFileList();
    response
      .then((result) => {
        setLoading(false);
        if (result !== undefined) {
          console.log(result);
          setBulletins(result);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
    // dispatch(getBulletinsAsync.request(null));
  }, []);

  return (
    <>
      <MetaTag
        title="낙원교회 - 주보"
        description="서울시 강북구 미아동에 위치한 대한예수교 장로회 합동측 낙원교회입니다."
        keywords="강북구, 미아동, 삼양동, 낙원교회"
        url=""
      ></MetaTag>
      <Section direction="Right" title="주보">
        <div className="section-body-container">
          {loading && <p style={{ textAlign: "center" }}>불러오는 중..</p>}
          {!loading && (
            <BulletinPager bulletins={bulletins}></BulletinPager>
            // <BulletinGrid
            //   bulletins={bulletins}
            //   onClick={(imageUrl) => {
            //     // if modal
            //     // setBulletinUrl(imageUrl);
            //     // setShowModal(true);
            //   }}
            // ></BulletinGrid>
          )}
          {/* {showModal && (
          <ModalImage
            imageUrl={bulletinUrl}
            onClick={() => {
              setShowModal(false);
            }}
          ></ModalImage>
        )} */}
          {/* {loading && <p style={{ textAlign: "center" }}>불러오는 중..</p>}
        {error && <p style={{ textAlign: "center" }}>에러 발생!</p>}
        {data && <VideoGrid videos={currentVideos(data)}></VideoGrid>} */}
          {/* <Pagination
          currentPage={currentPage}
          postsPerPage={bulletinsPerPage}
          totalPosts={data !== null ? data!.length : 0}
          paginate={setCurrentPage}
        ></Pagination> */}
        </div>
      </Section>
    </>
  );
}
