import React, { createContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Footer, Header } from "components";
import {
  HomeContainer,
  InfoChurchContainer,
  InfoLocationContainer,
  CommunityContainer,
  WritePostContainer,
  PostContainer,
  LoginController,
  SignUpContainer,
  NoticeListContainer,
  VideoListContainer,
  BulletinContainer,
  InfoWorshipContainer,
} from "containers";
import "sass/App.scss";
import { initAxios } from "api";
import { Helmet } from "react-helmet-async";

// Define the shape of the context
type PageIndexContextType = {
  currentPageIndex: number;
  setCurrentPageIndex: React.Dispatch<React.SetStateAction<number>>;
};

export const PageIndexContext = createContext<PageIndexContextType>({
  currentPageIndex: 0,
  setCurrentPageIndex: () => {},
});

function App() {
  // // Import the functions you need from the SDKs you need
  // import { initializeApp } from "firebase/app";
  // import { getAnalytics } from "firebase/analytics";
  // // TODO: Add SDKs for Firebase products that you want to use
  // // https://firebase.google.com/docs/web/setup#available-libraries

  // // Your web app's Firebase configuration
  // // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  // const firebaseConfig = {
  //   apiKey: "AIzaSyC3nUWCanbv5V3GiF154WHe3LEv630Z3ak",
  //   authDomain: "nakwonchojang-a1c07.firebaseapp.com",
  //   projectId: "nakwonchojang-a1c07",
  //   storageBucket: "nakwonchojang-a1c07.appspot.com",
  //   messagingSenderId: "531533905169",
  //   appId: "1:531533905169:web:934c982ec063c25d9a60b0",
  //   measurementId: "G-LT4SHK6EDT"
  // };

  // // Initialize Firebase
  // const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  const [currentPageIndex, setCurrentPageIndex] = useState(-1);

  useEffect(() => {
    initAxios();
    setBodyStyle();
  }, []);

  const setBodyStyle = () => {
    const body = document.body;
    body.style.minHeight = "100vh";
  };

  //TODO: When page Changed, scroll To top & hide dropdown

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        width: "100%",
        // maxWidth: "1500px",
        flexDirection: "column",
      }}
    >
      <PageIndexContext.Provider
        value={{ currentPageIndex, setCurrentPageIndex }}
      >
        <Helmet>
          <title>낙원교회</title>
          <meta
            name="description"
            content="서울시 강북구 미아동에 위치한 대한예수교장로회(합동) 낙원교회입니다."
          />
        </Helmet>
        <Header />
        <Routes>
          {/* use * instead of exact */}
          <Route path="/" element={<HomeContainer />}></Route>
          <Route path="/info/church" element={<InfoChurchContainer />}></Route>
          {/* <Route path="/info/people" element={<InfoPeopleContainer />}></Route> */}
          <Route
            path="/info/worship"
            element={<InfoWorshipContainer />}
          ></Route>
          <Route
            path="/info/location"
            element={<InfoLocationContainer />}
          ></Route>
          <Route path="/notice" element={<NoticeListContainer />}></Route>
          <Route
            path="/community/1"
            element={<CommunityContainer community={"senior"} />}
          ></Route>
          <Route
            path="/community/2"
            element={<CommunityContainer community={"school"} />}
          ></Route>
          <Route
            path="/community/3"
            element={<CommunityContainer community={"construct"} />}
          ></Route>
          <Route path="/bulletin" element={<BulletinContainer />}></Route>
          <Route path="/video" element={<VideoListContainer />}></Route>
          <Route
            path="/write"
            element={<WritePostContainer></WritePostContainer>}
          ></Route>
          <Route
            path="/:postType/:postId"
            element={<PostContainer></PostContainer>}
          ></Route>
          <Route
            path="/login"
            element={<LoginController></LoginController>}
          ></Route>
          <Route
            path="/signup"
            element={<SignUpContainer></SignUpContainer>}
          ></Route>
        </Routes>
      </PageIndexContext.Provider>
      <Footer />
    </div>
  );
}

export default App;
